import { ApplicationPanel, Box } from '@encompaas/common/components';
import { useEffect, useState } from 'react';
import { Panel } from './Panel';

export type ApplicationsProps = {
  applications: {
    name: string;
    Logo: (props: any) => JSX.Element;
    url: string;
  }[];
};

export const Applications = ({ applications }: ApplicationsProps) => {
  const [appPanelSize, setAppPanelSize] = useState<'small' | 'medium'>();

  // Set the size of the application panel based on the screen height
  useEffect(() => {
    function handleResize() {
      setAppPanelSize(
        window.innerHeight < 700 || window.innerWidth < 1200
          ? 'small'
          : 'medium'
      );
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  return (
    <Panel>
      <Box
        className={'app-panel'}
        background={'none'}
        direction={'column'}
        gap={'small'}
        padding={'none'}
        width={'100%'}
      >
        <Box
          background={'none'}
          direction='row'
          padding={'none'}
          alignItems='center'
          justifyContent={'center'}
          style={{ columnGap: '4%' }}
        >
          {applications.map((app) => (
            <ApplicationPanel key={app.name} {...app} size={appPanelSize} />
          ))}
        </Box>
      </Box>
    </Panel>
  );
};
