import { Box } from './Box';
import { Typography, useTheme } from '@mui/material';
import { ApplicationButtonProps } from './ApplicationButton';

//TODO: Is this component being used? it appears to be similar to ApplicationButton

// should create it's own props type
export type ApplicationPanelProps = {} & ApplicationButtonProps;

export const ApplicationPanel = ({
  name,
  Logo,
  className,
  url,
  size = 'medium',
}: ApplicationPanelProps) => {
  const theme = useTheme();

  let _className = `ecs-application-panel` + (size == 'medium' ? '' : '-small');

  //can apply custom className
  _className = _className + (className ?? '');

  const [width, height] = size == 'medium' ? ['30%', 12.5] : ['30%', 7];
  const [logoWidth, logoHeight] = size == 'medium' ? [4.375, 5] : [2.5, 2.8125];

  // Handle onClick event
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank'); // Opens the URL in a new window/tab
    }
  };

  return (
    //could use the Button component
    <Box
      className={_className}
      padding={'none'}
      alignItems='center'
      justifyContent='space-between'
      height={height}
      width={width}
      onClick={handleClick} // Add onClick event handler
      style={{ cursor: url ? 'pointer' : 'default' }} // Change cursor style if url is provided
    >
      <Logo
        className={'ecs-application-panel-logo'}
        width={`${logoWidth}rem`}
        height={`${logoHeight}rem`}
      />

      <Typography
        align='center'
        variant={'body1'}
        color={theme.palette.info.dark}
        className={'ecs-application-panel-name'}
      >
        {name}
      </Typography>
    </Box>
  );
};
